/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, CookieManager, Plyr) => {
        /*
        |
        | Constants
        |-----------
        */
        const
            $body = $('body'),
            $modalFormNews = $('#modal-form-news'),
            $cookieBanner = $('.cookie-banner'),
            $cookieClose = $cookieBanner.find('.item-close'),
            $cookieAccept = $cookieBanner.find('.item-accept'),
            $cookieRefuse = $cookieBanner.find('.item-refuse'),
            $customPlyrVideo = $('.custom-plyr-video')
            ;


        /*
        |
        | Cookie Manager
        |-----------------
        */
        // new CookieManager($cookieBanner, {
        //     name: 'adveris_cookie_use',
        //     duration: 30,
        //     buttons: {
        //         accept: $cookieAccept,
        //         refuse: $cookieRefuse,
        //         close: $cookieClose
        //     },
        //     onAccept: () => {
        //         console.log('accpeted')
        //     },
        //     onRefuse: () => {
        //         console.log('refused')
        //     }
        // });

        /*
        |
        | Modal (Form news)
        |---------
        */
        if ($body.hasClass('home')) {

            $modalFormNews.iziModal();

            const $closeBtn = $modalFormNews.find('#modal-form-news-close')

            setTimeout(() => {

                $modalFormNews.iziModal('open');

            }, 30000);

            $closeBtn.on('click', (e) => {

                $modalFormNews.iziModal('close');

            });

        }

        /*
        |
        | Videos
        |---------
        */
        $.each($customPlyrVideo, function () {
            const $video = $(this);
            const $videoPoster = $video.find('.item-poster');
            const $videoIcon = $video.find('.item-play-icon');
            const videoId = $video.data('id');
            const type = $video.data('type');
            const player = new Plyr(`#${videoId}`);

            console.log(type, player)

            $(`#${videoId}`).addClass('custom-plyr-video');

            const timeline = new TimelineMax({
                paused: true,
                onStart: () => {

                },
                onComplete: () => {
                    $videoIcon.addClass('pe-n');
                    $videoPoster.addClass('pe-n');
                },
                onReverseComplete: () => {
                    $videoIcon.removeClass('pe-n');
                    $videoPoster.removeClass('pe-n');
                }
            });

            timeline
                .to($videoIcon, 0.4, { opacity: 0, scale: 0.5, ease: Power0.easeNone }, 'start')
                .to($videoPoster, 0.6, { opacity: 0, ease: Power1.easeOut }, '-=0')


            $videoIcon.on('click', () => {
                if (timeline.totalProgress() > 0) {
                    player.pause();
                } else {
                    player.play();
                }
            });

            if (type == "file") {
                timeline.play();
            }

            player.on('play', () => { timeline.play().timeScale(1); });
            player.on('pause', () => { timeline.reverse().timeScale(1.2); });
        });
    }
}

/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|
| Importing Libs 
|------------------
*/
require('@lib/iziModal/js/iziModal.js')($);
import Swiper from 'swiper/js/swiper.min';
import { TweenMax } from "gsap/TweenMax";
import SplitText from "@lib/gsap-pro/SplitText";
import ScrollTo from "gsap/ScrollToPlugin";
import CustomEase from "@lib/gsap-pro/CustomEase";
// import 'scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap.js';
import 'scrollmagic/scrollmagic/uncompressed/plugins/jquery.ScrollMagic.js';
import 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js';
import ScrollMagic from 'scrollmagic';
import LocomotiveScroll from '@lib/locomotive-scroll/src/locomotive-scroll';
import Scrollbar from 'smooth-scrollbar';
import Plyr from 'plyr/src/js/plyr';

/*
|
| Importing Components
|-----------------------
*/
import CookieManager from '@components/cookie-manager';
import customGoogleMap from '@components/custom-google-map.js';
import MaterializeForm from '@components/materialize-form.js';
import Kira from '@components/kira.js';
import Menu from '@components/menu.js';
import Loader from '@components/loader.js';

/*
|
| Importing Utils
|-------------------
*/
import Router from '@utils/router.js';

/*
|
| Importing App files
|----------------------
*/
import * as app from '@components/global.js';
import main from '@pages/main.js';
import news from '@pages/news.js';
import animations from '@pages/animations.js';
import contact from '@pages/contact.js';
import home from '@pages/home.js';
import histoire from '@pages/histoire.js';
import savoirFaire from '@pages/savoir-faire.js';
import desserts from '@pages/desserts.js';
import recipes from '@pages/recipes.js';
import dessert from '@pages/dessert.js';

/*
|
| Routing
|----------
*/
const routes = new Router([ 
    {
        'file': animations,
        'dependencies': [app, Menu, Kira, CustomEase, ScrollMagic, LocomotiveScroll, Loader, Swiper]
    },
	{
		'file': main, 
		'dependencies': [app, CookieManager, Plyr]
    },
    {
        'file': home,
        'dependencies': [app, Swiper],
        'resolve': '#page-home'
    },
    {
        'file': histoire,
        'dependencies': [app, Swiper],
        'resolve': '#page-histoire'
    },
    {
        'file': savoirFaire,
        'dependencies': [app, Swiper],
        'resolve': '#page-savoir-faire'
    },
    {
        'file': desserts, 
        'dependencies': [app],
        'resolve': '#page-desserts'
    },
    {
        'file': recipes,
        'dependencies': [app],
        'resolve': '#page-recipes-archive'
    },
    {
        'file': dessert,
        'dependencies': [app],
        'resolve': '#page-dessert'
    },
    {
		'file': news, 
		'dependencies': [app],
		'resolve': '#page-news-archive'
    },
    {
        'file': contact,
        'dependencies': [app, MaterializeForm],
        'resolve': '#page-contact'
    }
]);

/*
|
| Run
|------
*/
(($) => { routes.load() })(jQuery);


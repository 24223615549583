/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, Swiper) => {

        /*
        |
        | Constants
        |------------
        */
        const
            $swiperPalets = $('.swiper-palets'),
            $swiperPaletsContainer = $swiperPalets.find('.swiper-container'),
            $swiperPaletsContent = $('.swiper-palets-slide-content'), 
            $swiperVideos = $('.swiper-videos'),
            $swiperVideosContainer = $swiperVideos.find('.swiper-container'),
            $swiperVideosContent = $('.swiper-videos-slide-content'), 
            $sectionMap = $('#section-map'),
            $ingredient = $sectionMap.find('[data-trigger-ingredient]')
        ;

        /*
        |
        | Swiper palets
        |----------------
        */
        $ingredient.on('click', function () {
            
            const $item = $(this);
            const index = $item.data('map-index');
            const $target = $($item.data('target'));
            const $active = $sectionMap.find('.item-map-card.active');
            const timeline = new TimelineMax({ paused: true, onComplete: () => app.updateLocomotiveScroll() });

            $ingredient.removeClass('active');
            $(`[data-map-index="${index}"]`).addClass('active');

            timeline
                .to($active, 0.6, { y: -30, opacity: 0, ease: Power1.easeOut })
                .set($active, { className: '-=active' })
                .set($target, { className: '+=active' })
                .fromTo($target, 0.7, { y: 30, opacity: 0, ease: Power1.easeOut }, { y: 0, opacity: 1, ease: Power1.easeOut })
            
            timeline.play()

        });


        /*
        |
        | Swiper palets
        |----------------
        */
        const interleaveOffset = 0.5;
        const swiperPalets = new Swiper($swiperPaletsContainer, {
            slidesPerView: 1,
            spaceBetween: 30,
            speed: 1000,
            navigation: {
                prevEl: $swiperPalets.find('.arrow-left'),
                nextEl: $swiperPalets.find('.arrow-right')
            },
            watchSlidesProgress: true,
            on: {
                progress: function () {
                    const swiper = this;
                    for (var i = 0; i < swiper.slides.length; i++) {
                        var slideProgress = swiper.slides[i].progress;
                        var innerOffset = swiper.width * interleaveOffset;
                        var innerTranslate = slideProgress * innerOffset;
                        $(swiper.slides[i]).find(".slide-inner").css('transform', "translate3d(" + innerTranslate + "px, 0, 0)");
                    }
                },
                touchStart: function () {
                    const swiper = this;

                    for (var i = 0; i < swiper.slides.length; i++) {
                        $(swiper.slides[i]).css('transition', "");
                    }
                },
                setTransition: function (speed) {
                    const swiper = this;

                    for (var i = 0; i < swiper.slides.length; i++) {
                        swiper.slides[i].style.transition = speed + "ms";
                        $(swiper.slides[i]).find(".slide-inner").css('transition', speed + "ms");

                    }
                }
            }
        });

        swiperPalets.on('slideChange', function(){
            const index = swiperPalets.realIndex

            $swiperPaletsContent.removeClass('active');
            $(`.swiper-palets-slide-content-${index}`).addClass('active');
        })


        /*
        |
        | Swiper videos
        |----------------
        */
        const swiperVideos = new Swiper($swiperVideosContainer, {
            effect: 'coverflow',
            centeredSlides: true,
            slidesPerView: 1,
            speed: 1000,
            coverflowEffect: {
                rotate: 0,
                stretch: 0,
                depth: 300,
                modifier: 1,
                slideShadows: true,
            },
            navigation: {
                prevEl: $swiperVideos.find('.arrow-left'),
                nextEl: $swiperVideos.find('.arrow-right')
            },
        });

        swiperVideos.on('slideChange', function () {
            const index = swiperVideos.realIndex

            $swiperVideosContent.removeClass('active');
            $(`.swiper-videos-slide-content-${index}`).addClass('active');
        })
    }
}

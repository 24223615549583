/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, Swiper) => {

        /*
        |
        | Constants
        |------------
        */
        const
            $swiperCreators          = $('.swiper-creators'),
            $swiperCreatorsContainer = $swiperCreators.find('.swiper-container')
        ;


        /*
        |
        | Swiper creators
        |------------------
        */
       const swiperCreators = new Swiper($swiperCreatorsContainer, {
            slidesPerView: 1.2,
            spaceBetween: 30,
            slidesPerGroup: 1,
            speed: 1000,
            navigation: {
                prevEl: $swiperCreators.find('.arrow-left'),
                nextEl: $swiperCreators.find('.arrow-right')
            },
            breakpoints: {
                520: {
                    slidesPerView: 1.5,
                    slidesPerGroup: 1,
                },
                768: {
                    slidesPerView: 2.5,
                    slidesPerGroup: 2,
                },
                1200:{
                    slidesPerView: 4.5,
                    slidesPerGroup: 4,
                }
            }
       })
    }
}

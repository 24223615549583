/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app) => {

        /*
        |
        | Constants
        |------------
        */
        const
            $sectionIntro  = $('#section-intro'),
            $tab  = $sectionIntro.find('.item-tab'),
            $tabBloc  = $sectionIntro.find('.item-tab-bloc')
        ;


        /*
        |
        | Tabs
        |-------
        */
        $tab.on('click', function(){
            const $item = $(this);
            const $target = $($item.data('target'));
            const $active = $sectionIntro.find('.item-tab-bloc.active');
            const timeline = new TimelineMax({
                paused: true,
                onComplete: () => app.updateLocomotiveScroll()
            });

            $tab.removeClass('active');
            $item.addClass('active');

            timeline
                .to($active, 0.6, { x: -30, opacity: 0, ease: Power1.easeOut })
                .set($active, { className: '-=active' })
                .set($target, { className: '+=active' })
                .fromTo($target, 0.7, { x: 30, opacity: 0, ease: Power1.easeOut }, { x: 0, opacity: 1, ease: Power1.easeOut })
            
            timeline.play()
        });
    }
}

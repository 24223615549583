/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, Menu, Kira, CustomEase, ScrollMagic, LocomotiveScroll, Loader, Swiper) => {
        /*
        |
        | Constants
        |-----------
        */
        const
            $html = $('html'),
            $body = $('body'),
            $pageLoader = $('#page-loader'),
            loaderAnimatedSvg = document.querySelector('#page-loader .item-logo svg:nth-child(2)'),
            $loaderLogo = $pageLoader.find('.item-logo'),
            scrollAreas = document.querySelectorAll('.scroll-area'),
            $header = $('#header'),
            $menuWrapper = $('#mobile-menu'),
            $menuButton = $('#header .btn-menu'),
            $menuBars = $menuButton.find('.item-burger > span'),
            $menuClose = $menuWrapper.find('.item-close'),
            $menuStaggerItems = $menuWrapper.find('[data-stagger-item]'),
            $menuHasChildren = $header.find('.menu-item-has-children'),
            $whiteHeaderPages = $('#page-home'),
            $backToTop = $('.back-to-top'),
            $splitItems = $('[data-splittext]'),
            $noLocomotivePages = $('.page-template-contact')
            ;


        /*
        |
        | Scroll Areas
        |---------------
        */
        Array.prototype.forEach.call(scrollAreas, element => {
            Scrollbar.init(element);
        });

        $backToTop.on('click', () => {
            app.scrollTo(0)
        });


        /*
        |
        | SplitText
        |------------
        */
        $.each($splitItems, function () {
            const $item = $(this);
            const type = $item.data('splittext') ? $item.data('splittext') : 'words, chars';
            const split = new SplitText($item, { type: type, linesClass: 'item-line', wordsClass: 'item-word', charsClass: 'item-char' });

            $item.addClass('splitted');
            $item[0]['split'] = split;
        });


        /*
        |
        | Loader
        |---------
        */
        if (sessionStorage.getItem('loaded_once') === null) {
            sessionStorage.setItem('loaded_once', 'loaded_once');
        }

        if ($pageLoader.hasClass('loader-active')) {
            let loaderProgress = { percentage: 0 };

            const loader = new Loader($pageLoader, {
                useWindowLoad: false,
                onProgress: () => { },
                onLoad: () => {
                    $pageLoader.addClass('active')
                    app.dispachEvent($body, 'dom:ready');
                }
            });

            loader.loaderTimeline
                .to(loaderProgress, 1.6, {
                    percentage: 100, ease: Power1.easeOut, onUpdate: () => {
                        loaderAnimatedSvg.style.clipPath = `polygon(0% 0%, ${loaderProgress.percentage}% 0%, ${loaderProgress.percentage}% 100%, 0% 100%)`;
                    }
                }, 'start')
                .to($loaderLogo, 0.8, { y: -100, ease: Power1.easeOut }, 'logo-=0')
                .to($loaderLogo, 0.4, { opacity: 0, ease: Power1.easeOut }, 'logo-=0')
                .to($pageLoader.find('.layer.above'), 1.6, { scaleY: 0, ease: Expo.easeInOut }, 'step2-=0.8')
                .to($pageLoader.find('.layer.behind'), 1.6, { scaleY: 0, ease: Expo.easeInOut }, 'step2-=0.74')
                .addCallback(() => { app.dispachEvent($body, 'loader:end'); }, 'step2-=0.2')

            loader.init();
        } else {
            const loader = new Loader($pageLoader, {
                useWindowLoad: false,
                onProgress: () => { },
                onLoad: () => {
                    app.dispachEvent($body, 'dom:ready');
                    app.dispachEvent($body, 'loader:end');
                }
            });

            loader.init();
        }



        /*
        |
        | Handle Loader End
        |--------------------
        */
        $body.on('dom:ready', () => {
            $html.addClass('loaded');

            if (!$noLocomotivePages.length) {
                window.locomotive = new LocomotiveScroll({
                    el: document.querySelector('.locomotive-scroll-container'),
                    smooth: true,
                    inertia: .7,
                    mouseMultiplier: 0.3,
                    getSpeed: true
                    //smoothMobile: true
                });
            }



            app.dispachEvent($body, 'locomotive:initialized');
        });


        /*
        |
        | Black Header
        |---------------
        */
        if ($whiteHeaderPages.length) {
            $header.addClass('header-white');
        }


        /*
        |
        | Menu
        |-------
        */
        const menu = new Menu($menuWrapper, $menuButton, {
            reverseTimeScale: 2
        });

        menu.menuTimeline
            .to($menuBars.eq(1), 0.3, { autoAlpha: 0 }, 'start')
            .to($menuBars.eq(0), 0.5, { x: 0, y: 8, rotation: 45, ease: Power1.easeOut }, 'start')
            .to($menuBars.eq(2), 0.5, { x: 0, y: -8, rotation: -45, ease: Power1.easeOut }, 'start')
            .to($menuWrapper, 0.5, { autoAlpha: 1, ease: Power1.easeOut }, 'start+=0.3')

        if ($whiteHeaderPages.length) {
            menu.menuTimeline
                .to($header, 0.1, { className: '-=header-white' }, 'start+=0.3')
        }

        // loader.loaderTimeline
        //     .staggerFrom($menuStaggerItems, 0.6, { autoAlpha: 0, x: 20, ease: Power1.easeOut }, '0.1', '+=0')

        menu.init();


        $menuWrapper.on('menu:open', () => {
            document.body.style.overflow = 'hidden';
            app.stopLocomotiveScroll()
        })
        $menuWrapper.on('menu:closed', () => {
            document.body.style.overflow = 'auto';
            app.startLocomotiveScroll()
        });

        $menuWrapper.find('.menu-item-has-children').on('click', function (e) {
            const $li = $(this);
            const $submenu = $li.find('> .submenu');

            $submenu.slideToggle(600)
        })

        $menuWrapper.find('.menu-item-has-children > a').on('click', function (e) {
            e.preventDefault();
        });


        /*
        |
        | Menu dropdown
        |----------------
        */
        // $menuHasChildren.on('mouseover', function(){
        //     // console.log('mouseover')
        //     const $li = $(this);
        //     const $submenuContainer = $li.find('.submenu-container');
        //     const $submenu = $li.find('.submenu');
        //     const timeline = new TimelineMax({ 
        //         paused: true,
        //         //onStart: () => { $submenuContainer.addClass('active'); },
        //         //onReverseComplete: () => { $submenuContainer.removeClass('active'); }
        //     });

        //     $submenuContainer.addClass('active');
        //     timeline
        //         .to($submenu.find('.item-layer'), 0.8, { scaleY: 1, skewY: 0, skewType: 'simple', force3D: false, ease: Expo.easeInOut }, 'start')
        //         .staggerTo($submenuContainer.find('li'), 0.5, { y: 0, opacity: 1, ease: Power1.easeOut }, 0.1, '-=0.35');

        //     if (!app.isDefined($submenuContainer[0]['timeline'])){
        //         $submenuContainer[0]['timeline'] = timeline
        //     }

        //     $submenuContainer[0]['timeline'].play().timeScale(1);

        // }).on('mouseleave', function (e) {
        //     // console.log('leave')
        //     e.stopImmediatePropagation()
        //     const $submenuContainer = $(this).find('.submenu-container');
        //     $submenuContainer.removeClass('active');
        //     $submenuContainer[0]['timeline'].reverse().timeScale(2);
        // });

        $menuHasChildren.on('click', function (e) {

            e.stopPropagation()

            const $li = $(this);
            const $submenuContainer = $li.find('.submenu-container');
            const $submenu = $li.find('.submenu');
            const timeline = new TimelineMax({
                paused: true,
                //onStart: () => { $submenuContainer.addClass('active'); },
                //onReverseComplete: () => { $submenuContainer.removeClass('active'); }
            });

            if (this.dataset.active == 'false') {

                this.dataset.active = 'true'

                $submenuContainer.addClass('active');
                timeline
                    .to($submenu.find('.item-layer'), 0.8, { scaleY: 1, skewY: 0, skewType: 'simple', force3D: false, ease: Expo.easeInOut }, 'start')
                    .staggerTo($submenuContainer.find('li'), 0.5, { y: 0, opacity: 1, ease: Power1.easeOut }, 0.1, '-=0.35');

                if (!app.isDefined($submenuContainer[0]['timeline'])) {
                    $submenuContainer[0]['timeline'] = timeline
                }

                $submenuContainer[0]['timeline'].play().timeScale(1);

            } else {

                this.dataset.active = 'false'

                $submenuContainer.addClass('active');
                $submenuContainer[0]['timeline'].reverse().timeScale(2);

            }

        });

        $body.not("#header").on('click', function (e) {

            e.stopImmediatePropagation()

            const $li = $header.find('.menu-item-has-children[data-active="true"]');
            $li[0].dataset.active = 'false';
            const $submenuContainer = $li.find('.submenu-container');
            $submenuContainer.removeClass('active');
            $submenuContainer[0]['timeline'].reverse().timeScale(2);

        });

        $('[data-base-image]').on('mouseover', function () {
            const $img = $(this);
            $img.attr('src', $img.data('hover-image'));

            console.log($img.data())
        }).on('mouseleave', function () {
            const $img = $(this);
            $img.attr('src', $img.data('base-image'));
        });


        /*
        |
        | Kira
        |-------
        */
        const kira = new Kira(ScrollMagic, {
            debug: false,
            loadEvent: {
                domElement: $body,
                eventName: 'loader:end'
            },
            optimize: true,
            options: {
                start: '-=0.6',
                triggerHook: 'onEnter'
            }
        });

        /*
        | splittext.smooth
        |--------------------
        */
        kira.add('splittext', ($item, timeline, start) => {
            const delay = $item.data('delay') ? $item.data('delay') : 0.02;

            timeline.staggerTo($item[0].split.chars, 1, { y: 0, opacity: 1, ease: Sine.ease0ut }, delay, start)
        });

        /*
        | fadeInUp.parallax.sm
        |-----------------------
        */
        kira.add('fadeInUp.to', ($item, timeline, start) => {
            timeline.to($item, 0.6, { y: 0, opacity: 1, ease: Power1.easeOut }, start)
        });

        /*
        | fadeInUp.parallax.sm
        |-----------------------
        */
        kira.add('fadeInUp.parallax.sm', ($item, timeline, start) => {
            timeline.from($item, 0.8, { y: 100, autoAlpha: 0, ease: Sine.easeOut }, start)
        });

        /*
        | fadeInUp.parallax
        |--------------------
        */
        kira.add('fadeInUp.parallax', ($item, timeline, start) => {
            timeline.from($item, 1, { y: 80, autoAlpha: 0, ease: Power1.easeOut }, start)
        });

        /*
        | fadeInUp.parallax.sm
        |-----------------------
        */
        kira.add('fadeInLeft.stagger.sm', ($item, timeline, start) => {
            timeline.staggerFrom($item.find('[data-stagger-item]'), 0.6, { autoAlpha: 0, x: 20, ease: Power1.easeOut }, '0.1', start)
        });

        kira.add('fadeInUp.stagger.sm', ($item, timeline, start) => {
            timeline.staggerFrom($item.find('[data-stagger-item]'), 0.6, { autoAlpha: 0, y: 20, ease: Power1.easeOut }, '0.1', start)
        });

        /*
        | fadeInUp.parallax
        |--------------------
        */
        kira.add('scaleX', ($item, timeline, start) => {
            timeline.from($item, 1, { scaleX: 0, transformOrigin: 'left top', ease: Expo.easeInOut }, start)
        });

        kira.init();
    }
}

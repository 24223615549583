/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app) => {

        /*
        |
        | Constants
        |------------
        */
        const
            $postsContainer  = $('.loaded-posts'),
            $loaderContainer = $('.loader-container'),
            $blocFilters     = $('.bloc-filters'),
            $itemFilter      = $blocFilters.find('.item-filter'),
            $loaderFilter    = $('#loader-filter'),
            $breadcrumbLast = $('.breadcrumbs .item-last'),
            $sectionDesserts = $('#section-desserts')
        ;


        /*
        |
        | Variables
        |------------
        */
        let 
            xhr   = null,
            state = {
                termId: 'all',
                termName: null
            }
        ;


        /*
        |
        | Filtering
        |-----------
        */
        $itemFilter.on('click', function (e) {
            //e.preventDefault();

            const $item     = $(this);
            const text      = $item.data('text');
            const imageUi   = $item.data('image-ui');
            const $prev     = $item.prev();
            const $titleCat = $('.item-title-cat .item-tb-title');

            $itemFilter.removeClass('active');
            $item.addClass('active');

            $sectionDesserts.find('.item-choco img').attr('src', imageUi);

            console.log(imageUi)


            $prev.find('.item-separator').fadeOut();
            $itemFilter.not($item).find('.item-separator').show();

            $breadcrumbLast.find('span').text(text);
            $titleCat.text(text);

            state.termId = $item.data('term-id');
            state.termName = $item.find('.item-filter-text').text();

            //ajaxFilter();
        });

        const $itemFilterPrev = $blocFilters.find('.item-filter.active').prev();

        if ($itemFilterPrev.length) {
            $itemFilterPrev.find('.item-separator').fadeOut()
        }


        /*
        |
        | Ajax Filter
        |--------------
        */
        function ajaxFilter() {
            if (xhr !== null) {
                xhr.abort()
            }

            const route = `/ajax/desserts/type/${state.termId}`;

            xhr = $.ajax({
                url: route,
                type: 'POST',
                dataType: 'json',
                beforeSend: () => {
                    $postsContainer.fadeOut(400, function () {
                        $(this).html('');
                        $loaderFilter.fadeIn(400);
                    });
                },
                success: (response, status) => {
                    $postsContainer.html(response).show();
                    const tl = new TimelineMax({
                        paused: true,
                        onComplete: () => {
                            app.updateLocomotiveScroll()
                        }
                    })
                    tl.staggerFrom($postsContainer.find('.col-dessert.loaded'), 0.8, {
                        autoAlpha: 0,
                        y: 30,
                        ease: Power1.easeOut
                    }, 0.1);
                    tl.play();
                    $postsContainer.find('.col-dessert.loaded').removeClass('loaded')
                    $loaderFilter.hide();
                    xhr = null;
                },
                error: (response, status, error) => {
                    console.log(response, status, error);
                }
            })
        }
    }
}

/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app) => {

        /*
        |
        | Constants
        |------------
        */
        const
            $sectionRecipes  = $('.section-recipes'),
            $itemFilter = $sectionRecipes.find('.filter-item'),
            $tabBloc  = $sectionRecipes.find('.item-tab-bloc')
        ;


        /*
        |
        | Variables
        |------------
        */
        let 
            xhr   = null,
            state = {
                termId: 'all'
            }
        ;

        
        /*
        |
        | FIltering
        |------------
        */
        $itemFilter.on('click', function () {
            const $item = $(this);
            const $target = $($item.data('target'));
            const $active = $sectionRecipes.find('.recipe-col.active');
            const timeline = new TimelineMax({
                paused: true,
                onComplete: () => app.updateLocomotiveScroll()
            });

            console.log('reco')

            $itemFilter.removeClass('active');
            $item.addClass('active');

            

            timeline
                .set($active, { clearProps: 'all' })
                .to($active, 0.6, { y: 30, opacity: 0, ease: Power1.easeOut })
                .set($active, { className: '-=active' })
                .set($target, { className: '+=active' })
                .fromTo($target, 0.7, { y: -30, opacity: 0, ease: Power1.easeOut }, { y: 0, opacity: 1, ease: Power1.easeOut })
            
            timeline.play()
        });


        /*
        |
        | Filtering recipes
        | --------------------
        */
        // var filters = document.querySelectorAll('.filter-item');

        // filters.forEach( filter => {

        //     filter.addEventListener("click", function(){
        //         var id = filter.getAttribute('data-id');

        //         if($(filter).hasClass('active')){

        //             $('.filter-item').removeClass('active no-active');
        //             filter.classList.remove('active');

        //             filtering("all");
        //         } else {

        //             //Toggling Classes
        //             $('.filter-item').removeClass('active no-active');
        //             $('.filter-item').not($(filter)).addClass('no-active');
        //             filter.classList.add('active');

        //             filtering(id);
        //         }
        //     })

        // })

        // function filtering(id){
        //     if(id !== "all"){
        //         //Show all
        //         $('.recipe-card').parent().show();
        //         //Filter
        //         $('.recipe-card').not("[data-card='"+id+"']").parent().hide();
        //     } else {
        //         $('.recipe-card').parent().show();
        //     }
        // }
    }
}

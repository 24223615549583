/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, MaterializeForm) => {
        
        const allQuestions = document.querySelectorAll('.question-container')
        
        allQuestions.forEach(item => {
            const   question = item.querySelector('.question'),
                    answer = item.querySelector('.answer'),
                    arrow = item.querySelector('.arrow')

            let tlAnswer = new TimelineMax({paused:true})
            tlAnswer.set(question, { className: "+=hide-after" }, 'start')
            tlAnswer.from(answer, .3, {height: 0}, 'start')
            tlAnswer.to(arrow, .3, { rotation: 180 }, 'start')

            let clicked = false;

            question.addEventListener('click', ()=> {
                if(!clicked){
                    tlAnswer.play()
                    clicked = true;
                } else {
                    tlAnswer.reverse()
                    clicked = false;
                }
            })
        })

        new MaterializeForm($('.contact-form'), {
            selectors: {
                group: '.gfield',
                label: '.gfield_label',
                input: '.ginput_container_text input, .ginput_container_email input'
            },
            labelEffect: {
                duration: 0.3,
                ease: Power2.easeOut
            }
        });

        $('#input_1_11').on('change', function () {
            var file = $(this).prop('files')[0];
            console.log($(this).closest('.file-input').find('.gfield_label'))
            $(this).closest('.file-input').find('.gfield_label').text(file.name)
        })

        

        $('body').on('change', '#easi_fielddiv_AttachedFiles #fld_AttachedFiles', function(){
            const file = $(this).prop('files')[0];
            const $fileLabel = $('#easi_fielddiv_AttachedFiles label');

            $fileLabel.text(file.name)
        });

        // new MaterializeForm($('.contact-form'), {
        //     selectors: {
        //         group: '.gfield',
        //         label: '.gfield_label',
        //         input: 'input[type="text"]'
        //     },
        //     labelEffect: {
        //         duration: 0.3,
        //         ease: Power2.easeOut
        //     }
        // });


        $(window).on('load', () => {
            new MaterializeForm($('.contact-form'), {
                selectors: {
                    group: '[id^="easi_fielddiv_"]',
                    label: '.[id^="id="easi_labelspan_"]',
                    input: 'input[type="text"]'
                },
                labelEffect: {
                    duration: 0.3,
                    ease: Power2.easeOut
                }
            });
        })
    }
}

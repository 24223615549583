/*** IMPORTS FROM imports-loader ***/
var define = false;

export const defaults = {
    el: document,
    elMobile: document,
    name: 'scroll',
    offset: [0,0],
    repeat: false,
    smooth: false,
    smoothMobile: false,
    direction: 'vertical',
    ease: 0.1,
    inertia: 1,
    class: 'is-inview',
    scrollbarClass: 'c-scrollbar',
    scrollingClass: 'has-scroll-scrolling',
    draggingClass: 'has-scroll-dragging',
    smoothClass: 'has-scroll-smooth',
    initClass: 'has-scroll-init',
    getSpeed: false,
    getDirection: false,
    mouseMultiplier: 0.4,
    firefoxMultiplier: 50,
    touchMultiplier: 2
};


/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, Swiper) => {
        /*
        |
        | Constants
        |------------
        */
        const
            $swiperHome = $('.swiper-home'),
            $swiperHomeContainer = $swiperHome.find('.swiper-container'),
            $swiperNews = $('.swiper-news'),
            $swiperNewsContainer = $swiperNews.find('.swiper-container')
        ;


        /*
		|
		| Swiper Home
		|--------------
        */
        const interleaveOffset = 0.5;
        const swiperHome = new Swiper($swiperHomeContainer, {
            autoplay: true,
            slidesPerView: 1,
            speed: 1000,
            navigation: {
                prevEl: $swiperHome.find('.arrow-left'),
                nextEl: $swiperHome.find('.arrow-right')
            },
            pagination: {
                el: '.swiper-home-pagination',
                type: 'bullets',
                clickable: true,
                renderBullet: function (index, className) {
                    return `
                        <span class = "`+ className + `" >
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                    viewBox="28 20 55 50" style="enable-background:new 28 20 55 50;" xml:space="preserve">
                                <g>
                                    <polygon stroke="#C49A2A" points="31.84,54.92 29.41,48.35 31.14,44.33 34.27,42.5 31.84,38.12 33.58,33.74 34.96,33 34.96,28.25 
                                        38.78,23.87 52.32,23.87 63.42,29.71 69.67,33.74 80.43,36.29 80.43,39.58 79.73,46.88 74.53,55.29 76.26,56.75 68.98,64.79 
                                        59.61,67.34 52.66,67.34 44.68,64.42 	"/>
                                </g>
                            </svg>
                        </span>`;
                }
            },
            watchSlidesProgress: true,
            on: {
                progress: function () {
                    const swiper = this;
                    for (var i = 0; i < swiper.slides.length; i++) {
                        var slideProgress = swiper.slides[i].progress;
                        var innerOffset = swiper.width * interleaveOffset;
                        var innerTranslate = slideProgress * innerOffset;
                        $(swiper.slides[i]).find(".slide-inner").css('transform', "translate3d(" + innerTranslate + "px, 0, 0)");
                    }
                },
                touchStart: function () {
                    const swiper = this;

                    for (var i = 0; i < swiper.slides.length; i++) {
                        $(swiper.slides[i]).css('transition', "");
                    }
                },
                setTransition: function (speed) {
                    const swiper = this;

                    for (var i = 0; i < swiper.slides.length; i++) {
                        swiper.slides[i].style.transition = speed + "ms";
                        $(swiper.slides[i]).find(".slide-inner").css('transition', speed + "ms");

                    }
                }
            }
        })


        /*
		|
		| Swiper News
		|--------------
        */
        const swiperNews = new Swiper($swiperNewsContainer, {
            slidesPerView: 1,
            speed: 1000,
            navigation: {
                prevEl: $swiperNews.find('.arrow-left'),
                nextEl: $swiperNews.find('.arrow-right')
            },
            pagination: {
                el: '.swiper-news-pagination',
                type: 'bullets',
                clickable: true,
                renderBullet: function (index, className) {
                    return `
                        <span class = "`+ className + `" >
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                    viewBox="28 20 55 50" style="enable-background:new 28 20 55 50;" xml:space="preserve">
                                <g>
                                    <polygon stroke="#cc9900" points="31.84,54.92 29.41,48.35 31.14,44.33 34.27,42.5 31.84,38.12 33.58,33.74 34.96,33 34.96,28.25 
                                        38.78,23.87 52.32,23.87 63.42,29.71 69.67,33.74 80.43,36.29 80.43,39.58 79.73,46.88 74.53,55.29 76.26,56.75 68.98,64.79 
                                        59.61,67.34 52.66,67.34 44.68,64.42 	"/>
                                </g>
                            </svg>
                        </span>`;
                }
            },
            
        })
    }
}
